import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ImageUploading from "react-images-uploading";
import SVGFront from "../../asset/image/id_front.3287c145.dbadd42c7a3ee0d534fcd405e9dd26ee.svg";
import SVGBack from "../../asset/image/id_back.fa1d0d27.4fe58f534073279b0e0064be94582db8.svg";
import * as formik from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import "../../asset/scss/component/modalUser.scss";
import { useTranslation } from "react-i18next";
import { setModalEditUser } from "../../redux/accction/acction";
import "../../asset/scss/component/editUser.scss";
import { veryficationFun } from "../../service/callAPi";
function Example() {
  const dispatch = useDispatch();
  const handleClose = () => dispatch(setModalEditUser(false));
  const [load, setLoad] = useState(false);
  const { t } = useTranslation();

  const [validated, setValidated] = useState(false);
  const user = useSelector((state) => state.reducers.user);
  const [step, setStep] = useState("1");
  const { Formik } = formik;
  const [imagesFront, setImagesFront] = useState([]);
  const [imagesBack, setImageBack] = useState([]);

  const [page, setPage] = useState(1);
  const initialValues = {
    fullname: user?.full_name || "",
    identification: user?.identity || "",
    bank_name: user?.bank_name || "",
    bank_number: user?.bank_number || "",
    imagesBackLink: user?.back_img || "",
    imagesFrontLink: user?.front_img || "",
    // wallet_number: user?.wallet_number || "",
    accept_identity: user?.accept_identity || 0,
  };

  const maxNumber = 1;

  const onChangeFront = (imagesFront) => {
    setImagesFront(imagesFront.slice(0, 1));
  };

  const onChangeBack = (imagesBack) => {
    setImageBack(imagesBack.slice(0, 1));
  };

  const schema = yup.object().shape({
    fullname: yup.string().required(),

    username: yup.string().required(),
    bank_name: yup.string().required(),
    bank_number: yup.string().required(),
  });

  const uploadUserInfor = (values) => {
    const formData = new FormData();
    formData.append("fullname", values.fullname);
    formData.append("bank_number", values.bank_number);
    formData.append("bank_name", values.bank_name);
    formData.append("identification", values.identification);
    formData.append("wallet_number", values.wallet_number);
    if (imagesFront.length > 0) {
      formData.append("frontImg", imagesFront[0].file);
    }
    if (imagesBack.length > 0) {
      formData.append("backImg", imagesBack[0].file);
    }

    VerificationInfor(formData);
  };

  const VerificationInfor = (data) => {
    dispatch(veryficationFun(data));
  };
  return (
    <div className="modal-custorm">
      <div className="modal-custorm-item w-100 h-100">
        <div className="modal-edit-user">
          <i class="fa-solid fa-xmark icon-close" onClick={handleClose}></i>
          <div className="modal-header d-flex justify-content-center ">
            <h3 className="ms-2 fs-5 mt-5 mb-3 text-center text-uppercase font-weight-bold">
              {" "}
              {t("update_information")}
            </h3>
          </div>
          <div className={`p-5 edit-user-custorm h-100`}>
            <Formik initialValues={initialValues}>
              {({ handleSubmit, handleChange, values, touched, errors }) => (
                <Form>
                  <Tabs
                    activeKey={step}
                    id="uncontrolled-tab-example"
                    onSelect={(key) => setStep(key)}
                    className="mb-3 tab-user d-flex justify-content-center  align-items-center ps-4 pe-4"
                  >
                    <Tab eventKey="1" title="1" className="tab-item-user">
                      <>
                        <Row className="mb-3">
                          <Form.Group
                            as={Col}
                            className="col-12 col-lg-12 mt-3 mt-lg-0"
                          >
                            <Form.Label>{t("fullname")}</Form.Label>
                            <Form.Control
                              type="text"
                              name="fullname"
                              className="bg-transparent color-white placeholder-white"
                              value={values.fullname}
                              onChange={handleChange}
                              isValid={touched.fullname && !errors.fullname}
                            />
                            <Form.Control.Feedback>
                              {t("looks_good")}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Row>
                        <Row className="mb-3">
                          <Form.Group as={Col} className="col-12">
                            <Form.Label>{t("identification")}</Form.Label>
                            <Form.Control
                              type="text"
                              name="identification"
                              className="bg-transparent color-white placeholder-white"
                              value={values.identification}
                              onChange={handleChange}
                              isValid={
                                touched.identification && !errors.identification
                              }
                            />
                            <Form.Control.Feedback>
                              {t("looks_good")}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Row>
                        <Row className="mb-3">
                          <Form.Group as={Col} className="col-12">
                            <Form.Label>{t("bank_number")}</Form.Label>
                            <Form.Control
                              type="text"
                              name="bank_number"
                              className="bg-transparent color-white placeholder-white"
                              value={values.bank_number}
                              onChange={handleChange}
                              isValid={
                                touched.bank_number && !errors.bank_number
                              }
                            />
                            <Form.Control.Feedback>
                              {t("looks_good")}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Row>
                        <Row className="mb-3">
                          <Form.Group as={Col} className="col-12">
                            <Form.Label>{t("bank_name")}</Form.Label>
                            <Form.Control
                              type="text"
                              name="bank_name"
                              className="bg-transparent color-white placeholder-white"
                              value={values.bank_name}
                              onChange={handleChange}
                              isValid={touched.bank_name && !errors.bank_name}
                            />
                            <Form.Control.Feedback>
                              {t("looks_good")}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Row>
                        <div className="d-flex justify-content-between align-items-center mt-4 me-2">
                            <div
                                className="btn-step bg-transparent d-flex justify-content-center p-3"
                                onClick={() => setStep("2")}
                            >
                                {t("next")}
                            </div>
                        </div>
                      </>
                    </Tab>
                    {/* <Tab eventKey="2" title="2" className="tab-item-user">
                      <div>
                        <p>
                          {t("identity_verification")}
                          <br />
                          {t("verification_instruction_1")}
                          <br />
                          {t("verification_instruction_2")}
                        </p>
                      </div>
                      <div className="scroll-y">
                        <div className="row mt-4">
                          <label className="mb-3">{t("front_id_photo")}</label>
                          <div className="col-6 box-image">
                            <ImageUploading
                              multiple={false}
                              value={imagesFront}
                              onChange={onChangeFront}
                              maxNumber={maxNumber}
                              dataURLKey="data_url"
                            >
                              {({
                                imageList,
                                onImageUpload,
                                onImageRemoveAll,
                                onImageUpdate,
                                onImageRemove,
                                isDragging,
                                dragProps,
                              }) => (
                                <div className="upload__image-wrapper">
                                  <div
                                    style={
                                      isDragging ? { color: "red" } : undefined
                                    }
                                    onClick={onImageUpload}
                                    {...dragProps}
                                    className="btn-upload-img btn-front-img d-flex justify-content-center align-items-center"
                                  >
                                    {imagesFront.length === 0 ? (
                                      initialValues.imagesFrontLink !== "" ? (
                                        <div className="image-item">
                                          <img
                                            src={
                                              process.env
                                                .REACT_APP_SERVER_STORAGE +
                                              initialValues.imagesFrontLink
                                            }
                                            alt=""
                                            width="100"
                                          />
                                        </div>
                                      ) : (
                                        <svg
                                          width={"64px"}
                                          height={"64px"}
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M17 17H17.01M15.6 14H18C18.9319 14 19.3978 14 19.7654 14.1522C20.2554 14.3552 20.6448 14.7446 20.8478 15.2346C21 15.6022 21 16.0681 21 17C21 17.9319 21 18.3978 20.8478 18.7654C20.6448 19.2554 20.2554 19.6448 19.7654 19.8478C19.3978 20 18.9319 20 18 20H6C5.06812 20 4.60218 20 4.23463 19.8478C3.74458 19.6448 3.35523 19.2554 3.15224 18.7654C3 18.3978 3 17.9319 3 17C3 16.0681 3 15.6022 3.15224 15.2346C3.35523 14.7446 3.74458 14.3552 4.23463 14.1522C4.60218 14 5.06812 14 6 14H8.4M12 15V4M12 4L15 7M12 4L9 7"
                                            stroke="#000000"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          ></path>
                                        </svg>
                                      )
                                    ) : (
                                      imagesFront.length > 0 && (
                                        <div className="image-item">
                                          <img
                                            src={imagesFront[0].data_url}
                                            alt=""
                                            width="100"
                                          />
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>
                              )}
                            </ImageUploading>
                          </div>
                          <div className="col-6 box-image">
                            <div className="btn-upload-img d-flex align-items-center justify-content-center">
                              <div className="image-item">
                                <img src={SVGFront} alt="" width="100" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-4">
                          <label className="mb-3">{t("back_id_photo")}</label>
                          <div className="col-6 box-image">
                            <ImageUploading
                              multiple={false}
                              value={imagesBack}
                              onChange={onChangeBack}
                              maxNumber={maxNumber}
                              dataURLKey="data_url"
                            >
                              {({
                                imageList,
                                onImageUpload,
                                onImageRemoveAll,
                                onImageUpdate,
                                onImageRemove,
                                isDragging,
                                dragProps,
                              }) => (
                                <div className="upload__image-wrapper">
                                  <div
                                    style={
                                      isDragging ? { color: "red" } : undefined
                                    }
                                    onClick={onImageUpload}
                                    {...dragProps}
                                    className="btn-upload-img btn-front-img d-flex justify-content-center align-items-center"
                                  >
                                    {imagesBack.length === 0 ? (
                                      initialValues.imagesBackLink !== "" ? (
                                        <div className="image-item">
                                          <img
                                            src={
                                              process.env
                                                .REACT_APP_SERVER_STORAGE +
                                              initialValues.imagesBackLink
                                            }
                                            alt=""
                                            width="100"
                                          />
                                        </div>
                                      ) : (
                                        <svg
                                          width={"64px"}
                                          height={"64px"}
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M17 17H17.01M15.6 14H18C18.9319 14 19.3978 14 19.7654 14.1522C20.2554 14.3552 20.6448 14.7446 20.8478 15.2346C21 15.6022 21 16.0681 21 17C21 17.9319 21 18.3978 20.8478 18.7654C20.6448 19.2554 20.2554 19.6448 19.7654 19.8478C19.3978 20 18.9319 20 18 20H6C5.06812 20 4.60218 20 4.23463 19.8478C3.74458 19.6448 3.35523 19.2554 3.15224 18.7654C3 18.3978 3 17.9319 3 17C3 16.0681 3 15.6022 3.15224 15.2346C3.35523 14.7446 3.74458 14.3552 4.23463 14.1522C4.60218 14 5.06812 14 6 14H8.4M12 15V4M12 4L15 7M12 4L9 7"
                                            stroke="#000000"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          ></path>
                                        </svg>
                                      )
                                    ) : (
                                      imagesBack.length > 0 && (
                                        <div className="image-item">
                                          <img
                                            src={imagesBack[0].data_url}
                                            alt=""
                                            width="100"
                                          />
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>
                              )}
                            </ImageUploading>
                          </div>
                          <div className="col-6 box-image">
                            <div className="btn-upload-img d-flex justify-content-center align-items-center">
                              <div className="image-item">
                                <img src={SVGBack} alt="" width="100" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mt-4 me-2">
                          <div
                            className="btn-step bg-transparent d-flex justify-content-center p-3"
                            onClick={() => setStep("1")}
                          >
                            {t("back")}
                          </div>
                          <div
                            className="btn-step bg-transparent d-flex justify-content-center p-3"
                            onClick={() => setStep("3")}
                          >
                            {t("continue")}
                          </div>
                        </div>
                      </div>
                    </Tab> */}

                    <Tab eventKey="2" title="2" className="tab-item-user">
                      {/*{(() => {*/}
                      {/*  switch (initialValues.accept_identity) {*/}
                      {/*    case 1:*/}
                      {/*      return <p>{t("verification_pending")}</p>;*/}
                      {/*    case 2:*/}
                      {/*      return <p>{t("verification_completed")}</p>;*/}
                      {/*    case 3:*/}
                      {/*      return <p>{t("verification_rejected")}</p>;*/}
                      {/*    default:*/}
                      {/*      return <p>{t("verification_not_started")}</p>;*/}
                      {/*  }*/}
                      {/*})()}*/}

                      {/*{initialValues.accept_identity === 0 && (*/}
                      {/*  <>*/}
                          <p className="text-center mt-2">
                            {t("press_done_to_complete")}
                          </p>
                          <div className="d-flex justify-content-between mt-4 me-2">
                            <div
                              className="btn-step bg-transparent d-flex justify-content-center p-3"
                              onClick={() => setStep("1")}
                            >
                              {t("back")}
                            </div>
                            <div
                              className="btn-step bg-transparent d-flex justify-content-center p-3"
                              onClick={() => uploadUserInfor(values)}
                              // disabled={load}
                            >
                              {load ? t("processing") : t("verify")}
                            </div>
                          </div>
                      {/*  </>*/}
                      {/*)}*/}
                    </Tab>
                  </Tabs>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Example;
