import React, { useState } from "react";
import "../../asset/scss/component/headerHome.scss";
import Logo from "../../asset/image/logo.553c8e5f.8b6accf9d8415837685b.png";
import { useSelector, useDispatch } from "react-redux";
import {
  setModalUser,
  setIsClickSlideBar,
  setModelVerify,
  setLang,
} from "../../redux/accction/acction";
import { useTranslation } from "react-i18next";
import { colors } from "@mui/material";
import { useLocation } from "react-router-dom";

function HeaderHome() {
  const location = useLocation();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const balanceVnd = useSelector((state) => state.reducers.balanceVnd);
  const balanceUsdt = useSelector((state) => state.reducers.balanceUsdt);
  const currentPath = location.pathname;
  const formattedbalanceVnd = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(balanceVnd);
  const formattedbalanceUsdt = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(balanceUsdt);
  const device = useSelector((state) => state.reducers.device);
  const isClickSlideBar = useSelector(
      (state) => state.reducers.isClickSlideBar
  );
  const notification_banned_vnd = useSelector(
      (state) => state.reducers.notification_banned_vnd
  );
  const notification_identity = useSelector(
      (state) => state.reducers.notification_identity
  );
  const lang = useSelector((state) => state.reducers.lang);

  const user = useSelector((state) => state.reducers.user);
  const [toggle, setToggle] = useState(false);
  const openModalUser = () => {
    dispatch(setModalUser(true));
  };

  return (
      <header
          id="myHeader"
          className={`d-flex justify-content-between align-items-center ps-lg-5 pe-lg-5 ps-4 pe-4 ${
              device !== "pc" ? "h-80-mobi pt-2 pb-2" : "h-80-header"
          }`}
          style={{ "padding-left": "5rem" }}
      >
        <div className="h-100">
          {device !== "pc" ? (
              <div
                  className="h-100 d-flex justify-content-between align-items-center icon-menu color-bule"
                  onClick={() => dispatch(setIsClickSlideBar(!isClickSlideBar))}
              >
                <i className="fa-solid fa-bars d-block d-lg-none fs-5"></i>
              </div>
          ) : (
              <img
                  src={Logo}
                  className="h-100 d-none d-lg-block"
                  alt={t("logoAlt")}
              />
          )}
        </div>
        <div className="d-none d-lg-block">
          {user !== null && user?.usdt_required === 1 && (
              <p
                  className="pointer-custom color-bule"
                  onClick={() => dispatch(setModelVerify(true))}
              >
                <i className="fa-solid fa-triangle-exclamation"></i>{" "}
                {t("verifyHere")}
              </p>
          )}
          {user !== null && user?.accept_identity !== 2 && (
              <p className="text-gray fw-bold">
                {notification_identity !== null &&
                    notification_identity?.length > 0 && (
                        <>
                          <i className="fa-solid fa-triangle-exclamation"></i>{" "}
                          {notification_identity}
                        </>
                    )}
              </p>
          )}
          {user !== null && user?.notification !== null && (
              <p className="text-gray fw-bold">
                {user?.notification?.length > 0 && <>{user?.notification}</>}
              </p>
          )}
          {user !== null &&
              user?.accept_withdraw === 1 &&
              currentPath === "/withdraw" && (
                  <p className="text-gray fw-bold">
                    {notification_banned_vnd !== null &&
                        notification_banned_vnd?.length > 0 && (
                            <>
                              <i className="fa-solid fa-triangle-exclamation"></i>{" "}
                              {notification_banned_vnd}
                            </>
                        )}
                  </p>
              )}
        </div>
        <div
            className={`${
                device !== "pc" &&
                "w-100 d-flex flex-column justify-content-between align-items-center"
            }`}
        >
          <div className="d-flex align-items-center right-item">
            <div className="d-lg-flex d-block">
              <div className="d-flex my-pay-money">
                <label htmlFor="">{t("usdt")}: </label>
                <p
                    className="color-green ms-1 fw-bold"
                    onClick={() => setToggle(!toggle)}
                >
                  {formattedbalanceUsdt} $
                </p>
              </div>
              <div className="d-flex my-pay-money ms-0 ms-lg-3">
                <label htmlFor="">{t("vnd")}:</label>
                <p
                    className="color-green ms-1 fw-bold"
                    onClick={() => setToggle(!toggle)}
                >
                  {formattedbalanceVnd} đ
                </p>
              </div>
              <div className="my-pay-money ms-0 ms-lg-3 d-none d-lg-flex">
                <label htmlFor="">{t("level")}:</label>
                <p className="ms-1 fw-bold" onClick={() => setToggle(!toggle)}>
                  {user !== null && user.level_user != null ? user.level_user : 0}
                </p>
              </div>
            </div>
            <div
                className="ms-4 text-center d-none d-lg-block"
                onClick={openModalUser}
            >
              <i className="fa-solid fa-gear"></i>
              <p>{t("profile")}</p>
            </div>
            <div className="ms-4 d-none d-lg-block">
              <p
                  className={`${lang === "vi" && "active"} lang-text`}
                  onClick={() => dispatch(setLang("vi"))}
              >
                Vi
              </p>
              <p
                  className={`${lang === "en" && "active"} lang-text ms-2 ms-lg-0`}
                  onClick={() => dispatch(setLang("en"))}
              >
                En
              </p>
            </div>
          </div>
          <div className="d-block d-lg-none">
            {user !== null && user.usdt_required === 1 && (
                <p
                    className="pointer-custom color-bule"
                    onClick={() => dispatch(setModelVerify(true))}
                >
                  <i className="fa-solid fa-triangle-exclamation"></i>{" "}
                  {t("verifyHere")}
                </p>
            )}
            {user !== null && user.accept_identity !== 2 && (
                <p className="text-gray fw-bold">
                  {notification_identity !== null &&
                      notification_identity?.length > 0 && (
                          <>
                            <i className="fa-solid fa-triangle-exclamation"></i>{" "}
                            {notification_identity}
                          </>
                      )}
                </p>
            )}
            {user !== null && user?.notification !== null && (
                <p className="text-gray fw-bold">
                  {user?.notification?.length > 0 && <>{user?.notification}</>}
                </p>
            )}
            {user !== null &&
                user.accept_withdraw === 1 &&
                currentPath === "/withdraw" && (
                    <p className="text-gray fw-bold">
                      {notification_banned_vnd !== null &&
                          notification_banned_vnd.length > 0 && (
                              <>
                                <i className="fa-solid fa-triangle-exclamation"></i>{" "}
                                {notification_banned_vnd}
                              </>
                          )}
                    </p>
                )}
          </div>
        </div>
        <div className="d-flex my-pay-money ms-0 ms-lg-3 d-block d-lg-none">
          <label htmlFor="">{t("level")}:</label>
          <p className="ms-1 fw-bold" onClick={() => setToggle(!toggle)}>
            {user !== null && user.level_user != null ? user.level_user : 0}
          </p>
        </div>
      </header>
  );
}

export default HeaderHome;
