import Table from "react-bootstrap/Table";
import { getHistoty } from "../../service/callAPi";
import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { formatDate1, formatPrice1 , formatPriceUsdt } from "../../service/funweb";
import { setPage } from "../../redux/accction/acction";
import { useTranslation } from 'react-i18next';

function DepositWithdraw({ type }) {
  const [isLoadmore, setIsLoadmore] = useState(false);
  const [limit, setLimit] = useState(10);
  const listHistory = useSelector((state) => state.reducers.listHistory);
  const isDispatch = useSelector((state) => state.reducers.isDispatch);
  const page = useSelector((state) => state.reducers.page);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (isDispatch === true) {
      dispatch(
        getHistoty(
          type,
          page,
          limit,
          isLoadmore,
          setIsLoadmore,
          setPage,
          isDispatch
        )
      );
    }
  }, [type, page]);

  const loadmore = () => {
    if (isDispatch === true) {
      dispatch(setPage(page + 1));
      setIsLoadmore(true);
    }
  };

  return (
    <>
      <Table striped bordered hover className="table-responsive">
        <thead>
          <tr>
            <th>{t('time')}</th>
            <th>{type === "withdraw" ? t('moneyVNĐ') : t('moneyUSDT')}</th>
            <th>{t('category')}</th>
            <th>{type === "convert" ? t('transaction_type') : t('status')}</th>
            {type === "convert" && <th>{t('amount_vnd')}</th>}
            <th>{t('content')}</th>
          </tr>
        </thead>
        <tbody>
          {listHistory.length > 0 ? (
            listHistory.map((item, index) => (
              <tr key={index}>
                <td data-label={t('time')}>{formatDate1(item.created_at)}</td>
                <td data-label={t('amount')}>{type !== "convert" ? (item.amount >0 && formatPrice1(item.amount)) : formatPriceUsdt(item.amount_usd)}</td>
                <td data-label={t('category')}>
                  {(() => {
                    switch (type) {
                      case "convert":
                        return t('sell_usdt');
                      case "recharge":
                        return t('deposit_usdt');
                      default:
                        return t('withdraw_vnd');
                    }
                  })()}
                </td>
                <td data-label={t('status')}>
                  {type !== "convert"
                    ? (() => {
                        switch (item.is_approve) {
                          case 0:
                            return t('pending_approval');
                          case 1:
                            return t('approved');
                          default:
                            return t('not_approved');
                        }
                      })()
                    : item.is_group !== null
                    ?  t('combined_sell')
                    : t('automatic_sell')}
                </td>
                {type === "convert" && (
                  <td data-label={t('amount_vnd')}>
                    {item.amount_vnd && item.amount_vnd !== null
                      ? formatPrice1(item.amount_vnd)
                      : t('no_data')}
                  </td>
                )}
                <td data-label={t('content')}>
                  {item.description && item.description !== null
                    ? item.description
                    : t('no_content')}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td className="text-center justify-content-center" colSpan={type === "recharge" ? 5 : 6}>{t('no_data')}</td>
            </tr>
          )}
        </tbody>
      </Table>
      {isLoadmore && (
        <div className="text-center mt-5 d-flex justify-content-center align-items-center">
          <div className="btn-loadmore" onClick={loadmore}>
            {isDispatch === true ? t('load_more') : t('loading')}{" "}
          </div>
        </div>
      )}
    </>
  );
}

export default DepositWithdraw;
