import React, { useState, useEffect } from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {setModalUser, setIsClickSlideBar} from '../../redux/accction/acction';
import { useLocation } from 'react-router-dom';

function BottomNavbar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [value, setValue] = useState(0);
  const { t } = useTranslation();
  const currentPath = location.pathname;

    const openModaluser =() =>{
      dispatch(setModalUser(true));
      dispatch(setIsClickSlideBar(false))
    }
  const depositWithdraw = (actionType) => {
    dispatch(setIsClickSlideBar(false))
    switch (actionType) {
      case 'DEPOSIT':
        navigate('/depositWithdraw');
        break;
      case 'WITHDRAW':
        navigate('/withdraw');
        break;
      case 'HOME':
        navigate('/home');
        break;
      default:
        navigate('/');
    }
  };
  return (
    <BottomNavigation
      className='d-block d-lg-none navbar-custorm w-100 d-flex justify-content-center align-items-center ' 
      id="navbar"
      showLabels
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
    >
      <BottomNavigationAction className={`${currentPath === '/home' && 'active' }`} onClick={()=>depositWithdraw('HOME')} label={t('transactions')} icon={<i className="fa-solid fa-house mb-2"></i>} />
      <BottomNavigationAction  className={`${currentPath === '/depositWithdraw' && 'active' }`} onClick={()=>depositWithdraw('DEPOSIT')} label= {t('usdt_wallet')} icon={<i className="fa-sharp-duotone fa-solid fa-credit-card-alt mb-2"></i>} />
      <BottomNavigationAction  className={`${currentPath === '/withdraw' && 'active' }`} onClick={()=>depositWithdraw('WITHDRAW')} label={t('vnd_wallet')}  icon={<i className="fa-sharp-duotone fa-solid fa-credit-card-alt mb-2"></i>  } />
      <BottomNavigationAction  onClick={openModaluser} label={t('profile')}  icon={<i class="fa-solid fa-user mb-2"></i>} />
    </BottomNavigation>
  );
}

export default BottomNavbar;
