import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { convertFun } from "../../service/callAPi";
import { formatDate1, formatPriceUsdt } from "../../service/funweb";
import { useTranslation } from 'react-i18next';

function ProjectGroup() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const listProjectGroup = useSelector(state => state.reducers.listProjectGroup);
  const [group, setGroup] = useState(null);
  const [amount, setAmount] = useState(0);
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setGroup(null);
    setAmount(0);
  };

  const handleShow = (item) => {
    setShow(true);
    setGroup(item.name);
  };

  const postAPi = () => {
    dispatch(convertFun(
      {
        amount_usd: amount,
        group: group
      },
      () => {
        setAmount(0);
        handleClose();
      }
    ));
  };

  return (
    <div className="responsive-table two-axis">
      <h3 className='text-center mt-4 mb-4 fs-5 fw-bold'>{t('sell_combined')}</h3>
      <Table striped bordered hover className="table-responsive">
        <thead>
          <tr>
            <th className="text-center">{t('nameBot')}</th>
            <th className="text-center">{t('quantity')}</th>
            <th className="text-center">{t('payyed')}</th>
            <th className="text-center">{t('payying')}</th>
            <th className="text-center">{t('min')}</th>
            {/* <th className="text-center">{t('max')}</th> */}
            {/* <th className="text-center">{t('time')}</th> */}
            <th className="text-center">{t('action')}</th>
          </tr>
        </thead>
        <tbody>
          {listProjectGroup.length > 0 &&
            listProjectGroup.map((item, index) => (
              <tr key={index}>
                 <td className="text-lg-center" data-label={t('nameBot')}>{item.name}</td>
                 <td className="text-lg-center" data-label={t('quantity')}>{formatPriceUsdt(item.sum_value)} USDT</td>
                <td className="text-lg-center" data-label={t('payyed')}>{formatPriceUsdt(item.value)} USDT</td>
                <td className="text-lg-center" data-label={t('payying')}>{item.sum_value - item.value - item.amount_invested <= 0 ? 0 : formatPriceUsdt(item.sum_value - item.value -item.amount_invested)} USDT</td>
                {/* <td className="text-lg-center" data-label={t('time')}>{formatDate1(item.created_at)}</td> */}
                <td className="text-lg-center" data-label={t('min')}>{item.min} USDT</td>
                {/* <td className="text-lg-center" data-label={t('max')}>{item.max} USDT</td> */}
                <td className="text-lg-center" data-label={t('action')}>
                  <Button className='btn-invest' onClick={() => handleShow(item)}>{t('sell')}</Button>
                </td>
              </tr>
            ))
          }
        </tbody>
      </Table>

      <Modal show={show} onHide={handleClose} className='modal-custom'>
        <Modal.Header closeButton>
          <Modal.Title>{t('sell_combined')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-3">
            <Form.Group as={Col} md="12">
              <Form.Label>{t('usdt_amount')}</Form.Label>
              <Form.Control
                required
                type="number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
              <Form.Control.Feedback>{t('input_valid')}</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Button variant="primary" onClick={postAPi}>{t('sell')}</Button>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ProjectGroup;
