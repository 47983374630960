import Modal from 'react-bootstrap/Modal';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setModalUser, setModalEditUser, setModalUpdatePassword } from "../../redux/accction/acction";
import "../../asset/scss/component/modalUser.scss";
import Default from "../../asset/image/default.png";
import ImageUploading from 'react-images-uploading';
import { toast } from 'react-toastify';
import { updateImageFun } from "../../service/callAPi";
import { useTranslation } from 'react-i18next';

function Example() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleClose = () => dispatch(setModalUser(false));
  const user = useSelector(state => state.reducers.user);
  const [imageShow, setImageNow] = useState(null);
  const device = useSelector((state) => state.reducers.device);
  const [images, setImages] = useState([]);
  const maxNumber = 1;

  useEffect(() => {
    if (user !== null) {
      setImageNow(process.env.REACT_APP_SERVER_STORAGE + user.avatar);
    }
  }, [user]);

  const onChange = (imageList) => {
    const img = imageList[0].file;
    const formData = new FormData();
    formData.append('img', img);
    dispatch(updateImageFun(formData));
  };

  return (
    <div className="modal-custorm">
      <div className='modal-custorm-item w-100 h-100'>
        <div className='modal-user-item'>
          <i className="fa-solid fa-xmark icon-close" onClick={handleClose}></i>
          <div className='titel w-100 mt-3 mb-3'>
            <h3 className='text-center fs-5 fw-bloder'>{t('profile')}</h3>
          </div>
          <div className={`p-4 user-infro ${device !== "pc" ? "he-500" : "he-auto"}`}>
            <div className='image-user mb-4 d-flex justify-content-start align-items-center'>
              <div className='modal-image justify-content-start ms-1'>
                <img src={imageShow} className="" alt="User Avatar" />
              </div>
              <div className='ms-3'>
                <ImageUploading
                  multiple={false}
                  value={images}
                  onChange={onChange}
                  maxNumber={maxNumber}
                  dataURLKey="data_url"
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                  }) => (
                    <button
                      className='update-avatar bg-transparent p-4 pt-2 pb-2'
                      onClick={onImageUpload}
                      {...dragProps}
                    >
                      {t('change_avatar')}
                    </button>
                  )}
                </ImageUploading>
              </div>
            </div>
            <div>
              <div className='d-flex justify-content-start align-items-center'>
                <i className="fa-solid fa-user"></i>
                <p className='fs-4 fw-bloder ms-3'>{t('account_info')}</p>
              </div>
              <form className='show'>
                <div>
                  <label className='m-2 ms-0 me-0'>{t('account_name')}</label><br />
                  <input
                    type="text"
                    style={{ color: "white" }}
                    value={user !== null && user.full_name !== null ? user.full_name : ''}
                    className='w-100 p-4 pt-2 pb-2 bg-transparent'
                    disabled
                  />
                </div>
                <div className='d-flex mt-3 align-items-center'>
                  <label className='m-2 ms-0 me-0'>{t('account_id')} </label>
                  <p className='ms-2'> #{user !== null && user.id !== null && user.id + 55300}</p>
                </div>
              </form>
            </div>
            <div>
              <div className='mt-3 d-flex justify-content-start align-items-center'>
                <svg width="22px" height="22px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                  <g id="SVGRepo_iconCarrier">
                    <path d="M14 19.2857L15.8 21L20 17M4 21C4 17.134 7.13401 14 11 14C12.4872 14 13.8662 14.4638 15 15.2547M15 7C15 9.20914 13.2091 11 11 11C8.79086 11 7 9.20914 7 7C7 4.79086 8.79086 3 11 3C13.2091 3 15 4.79086 15 7Z" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  </g>
                </svg>
                <p className='fs-5 fw-bloder ms-3'>{t('account_info')}</p>
              </div>
              <div className='mt-3'>
                <p>{t('verification_info')}</p>
                <button onClick={() => dispatch(setModalEditUser(true))} className='float-right mt-2 btn-accuracy bg-transparent'>{t('confirm')}</button>
              </div>
            </div>
            <div>
              <div className='mt-3 d-flex justify-content-start align-items-center'>
                <i className="fa-solid fa-lock"></i>
                <p className='fs-5 fw-bloder ms-3'>{t('security')}</p>
              </div>
              <div className='mt-3'>
                <p>{t('change_password_prompt')}</p>
                <button className='float-right mt-2 btn-accuracy bg-transparent' onClick={() => dispatch(setModalUpdatePassword(true))}>{t('change_password')}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Example;
