import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { formatCurrency, formatPrice1 } from "../../service/funweb";
import { convertFun } from "../../service/callAPi";
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

function Convert() {
    const { t } = useTranslation(); // Hook để lấy hàm dịch
    const [amount, setAmount] = useState(null);
    const dispatch = useDispatch();

    const rate = useSelector(state => state.reducers.rate);
    const transactionrate = useSelector(state => state.reducers.transactionrate);
    const balanceUsdt = useSelector(state => state.reducers.balanceUsdt);

    const postAPI = () => {
        if (Number(amount) <= 0) {
            toast.error(t('error_amount_required'));
            return;
        }
        const roundedAmount = Number(amount).toFixed(2);
        dispatch(convertFun(
            {
                amount_usd: Number(roundedAmount)
            }, setAmount
        ));
    }

    const buy = () => {
        toast.error(t('become_merchant'));
    }

    return (
        <div className='w-100 mt-4 mt-lg-0 table-convert p-4'>
            <h4 className='text-center fs-5 fw-blod mt-0 mb-3'>
                {t('exchange_usdt')}
            </h4>
            <div>
                <div className='custorm-box w-100 p-3 d-flex flex-column'>
                    <div className="d-flex flex-row p-2 align-items-center">
                        <label>{t('sell')}: </label>
                        <input type='number' style={{ fontSize: "15px" }} min="1" value={amount ? Number(amount).toFixed(2) : ''} placeholder={t('enter_amount')}
                            className='bg-dark w-100 ms-2 p-2' onChange={(e) => setAmount(e.target.value)} />
                        <span className="ms-1">USDT</span>
                    </div>
                    <button className='btn-acction bg-red ms-2 p-1' onClick={() => setAmount(balanceUsdt)}>
                        {t('sell_all')}
                    </button>
                </div>

                <div className='custorm-box w-100 mt-3 p-3'>
                    <label>{t('receive')}:</label>
                    {amount > 0 && (
                        <>
                            <p className='mt-3'>{`${t('amount_to_convert')} :  ${formatCurrency(Math.round(Number(amount) * rate))} VND`}</p>
                            {/*<p className='mt-3'>{`${t('commission')} : ${formatCurrency(Math.round((Number(amount) * rate) / 100 * transactionrate))} VND`}</p>*/}
                            <p className='mt-3'>{`${t('total_received')}: ${formatCurrency(Math.round(Number(amount) * rate + (Number(amount) * rate) / 100 * transactionrate))} VND`}</p>
                        </>
                    )}
                </div>
                <p className='mt-4 fw-bold'>
                    {t('transaction_rate')}:
                    <span className="text-primary ms-1">
                        1 USDT = {formatPrice1(Math.round(rate))} VND
                    </span>
                </p>
                <p className='mt-4 fw-bold'>
                    {t('best_rate')}:
                    <span className="text-primary ms-1">
                        1 USDT = {formatPrice1(Math.round(rate * ((100 + transactionrate) / 100)))} VND
                    </span>
                </p>
                <button onClick={postAPI}
                    className='w-100 pt-3 pb-3 bg-red btn-acction mt-3 d-flex justify-content-center align-items-center'>
                    <svg width="20px" height="20px" className="ms-1" viewBox="0 0 24 24" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                        <g id="SVGRepo_iconCarrier">
                            <path fillRule="evenodd" clipRule="evenodd"
                                d="M5 5C5 4.44772 4.55228 4 4 4C3.44772 4 3 4.44772 3 5V6.99983C3 6.99994 3 7.00006 3 7.00017L3 19C3 19.5523 3.44772 20 4 20H20C20.5523 20 21 19.5523 21 19C21 18.4477 20.5523 18 20 18H5V9.41421L8.29289 12.7071C8.68342 13.0976 9.31658 13.0976 9.70711 12.7071L13 9.41421L17.5858 14H15C14.4477 14 14 14.4477 14 15C14 15.5523 14.4477 16 15 16H20C20.5523 16 21 15.5523 21 15V10C21 9.44772 20.5523 9 20 9C19.4477 9 19 9.44772 19 10V12.5858L13.7071 7.29289C13.3166 6.90237 12.6834 6.90237 12.2929 7.29289L9 10.5858L5 6.58579V5Z"
                                fill="#ffffff"></path>
                        </g>
                    </svg>
                    <p className='ms-3'>{t('sell')}</p>
                </button>
                <button onClick={buy}
                    className='w-100 pt-3 pb-3 mt-4 bg-green btn-acction d-flex justify-content-center align-items-center'>
                    <i className="fa-solid fa-chart-line ms-1"></i>
                    <p className='ms-3'>{t('buy')}</p>
                </button>
            </div>
        </div>
    )
}

export default Convert;
